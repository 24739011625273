import { getSplashScreen, getStartpage } from '../content/contentfulClient';
import { Startpage } from 'components';

export const getStaticProps = async () => {
  const startpage = await getStartpage();
  const splashScreen = await getSplashScreen();
  const startpageFields = startpage?.fields || {};
  const splashScreenFields = splashScreen?.fields || {};
  const fields = { ...startpageFields, splashScreenFields };

  return {
    props: {
      ...fields,
    },
  };
};

const Home = props => {
  return <Startpage {...props} />;
};

export default Home;
